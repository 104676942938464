[data-custom-class='body'], [data-custom-class='body'] * {
    background: transparent !important;
    }
[data-custom-class='title'], [data-custom-class='title'] * {
font-size: 26px !important;
    }
[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
font-size: 14px !important;
    }
[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
font-size: 19px !important;
    }
[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
font-size: 17px !important;
    }
[data-custom-class='body_text'], [data-custom-class='body_text'] * {
font-size: 14px !important;
    }
[data-custom-class='link'], [data-custom-class='link'] * {
font-size: 14px !important;
word-break: break-word !important;
    }
    ul {
        list-style-type: square;
    }
    ul > li > ul {
        list-style-type: circle;
    }
    ul > li > ul > li > ul {
        list-style-type: square;
    }