
.bracket-wizard {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 1em;
    margin-bottom: 1em;
}

.format-form {
    max-width: 100%;
}

.participant-selection-grid {
    padding-top: 1em;
    display: flex;
    flex-wrap: wrap;
    gap: 3em;
    row-gap: 1.5em;
}

.team-selector {
    position: relative;
    height: 3em;
    width: fit-content;
    min-width: 10em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.team-selector::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    border: solid 1px white;
    border-radius: 5px;
    z-index: 1;
    pointer-events: none;
}

.seed-preview-box {
    height: 100%;
    width: 2em;
    border-right: solid 1px rgb(196, 196, 196);
    display: flex;
    justify-content: center;
    align-items: center;
}

.seed-preview {
    height:fit-content;
}

.team-selector-logo {
    height: 100%;
    max-width: 3em;
    display: flex;
    justify-content: end;
    align-items: center;
}

.team-logo {
    max-width: 80%;
    max-height: 80%;
    object-fit: contain;
}

.team-selector-name {
    padding-left: .3em;
    padding-right: .3em;
}

.team-selector-dropdown {
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.team-selector-dropdown:hover {
    background-color: var(--input-hover-background-color);
}

.dropdown-button {
    height: 3em;
    width: fit-content;
    padding: .5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown-button > svg {
    height: fit-content;
    width: fit-content;
}

.add-stage-button {
    cursor: pointer;
}

.add-stage-button:hover {
    backdrop-filter: brightness(196%);
}

.stage-form-title {
    flex-grow: 1;
    border: 1px solid transparent;
}

.stage-form-title:hover {
    cursor: text;
    border: 1px solid var(--bs-gray-500);
    background-color: var(--input-background-color);
}

.stage-form-title.selected {
    cursor: text;
    border: 1px solid var(--bs-gray-500);
    background-color: var(--input-background-color);
}

.stage-form-title-editing {
    padding: 0;
    margin: 0;
    cursor: text;
    background:transparent;
    color: white;
    border: none;
    font-weight: 500;
    outline: none;
}

.stage-delete-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    max-height: 3em;
    max-width: 3em;
    padding: .3em;
    border-radius: .5em;
}

.stage-delete-button:hover {
    filter: brightness(196%);
    background-color: var(--bs-gray-800);
}

.stage-delete-button img {
    max-width: 100%;
    height: auto;
}

.groups-form-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(calc(50% - .5em), 1fr));
    gap: 1em;
}

@media(max-width: 715px) {
    .groups-form-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}

.stage-group {
    max-width: 100%;
    overflow-x: scroll;
}

.format-selector {
    max-width: 13em !important;
    margin-bottom: .5em;
}

.match-count {
    position: absolute;
    font-size: .75em;
    padding: inherit;
    padding-bottom: 0;
    top: 0;
    right: 0;
    z-index: -1;
}

.participant-count {
    position: absolute;
    font-size: .75em;
    padding: inherit;
    padding-bottom: 0;
    top: 0;
    left: 0;
    z-index: -1;
}

.seed-siphon {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5em 0;
}

.siphon-seed {
    font-size: 1.5em;
    min-width: 1.5em;
    border: 1px solid white;
    border-radius: 5px;
    text-align: center;
    position: relative;
}

.siphon-seed.unused {
    background-color: var(--bs-gray-800);
    margin-left: .5em;
}

.switch-seeds {
    margin-left: .5em;
    margin-right: .5em;
    padding-left: .3em;
    padding-right: .3em;
    border: 1px solid white;
    border-radius: 5px;
    background-color: var(--input-background-color);
    cursor: pointer;
}

.seed-divider {
    font-size: 1.5em;
    margin-left: .5em;
    border-left: 1px solid white;
    height: 2em;
}

.seed-button {
    display: none;
    cursor: pointer;
}

.siphon-seed:hover > .seed-button {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    font-size: .5em;
    text-align: center;
    cursor: pointer;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    border-top-right-radius: 5px;
}

.seed-button.add {
    background-color: green;
}

.seed-button.remove {
    background-color: red;
}

.group-selector {
    background-color: var(--input-background-color) !important;
    color: white !important;
    display: flex;
    align-items: center;
    width: fit-content;
}

.group-selector > div {
    font-size: 1.2em;
    background-color: var(--input-background-color) !important;
    color: white !important;
    padding-left: .5em;
    padding-right: .5em;
    border: 1px solid white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 2.35rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.group-selector > select {
    max-width: 4em !important;
    background-color: var(--input-background-color) !important;
    color: white !important;
    border-color: white !important;
    height: 2.35rem;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.groups-divider {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5em .5em;
    margin-bottom: .5em;
}

.tiebreaker-select {
    width: fit-content;
    max-width: 100%;
}

.tiebreaker-option-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    whixte-space: nowrap;
    mxax-width: auto;
    margin-bottom: .5em;
    border: 1px solid white;
    border-radius: .75em;
}

.tiebreaker-option-container.unused {
    background-color: var(--bs-gray-800);
}

.tiebreaker-option {
    flex: 1 1 auto;
    padding: .75em;
    overflow: hidden;
    overfxlow-wrap: unset;
    text-overflow: ellipsis;
    white-space: nowrap;
    bordxer: 1px solid white;
    borxder-radius: .75em;
    borxder-top-right-radius: 0;
    bordxer-bottom-right-radius: 0;
}

.tiebreaker-option-buttons {
    flex: 0 0 auto;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 2em;
    max-width: 2em;
    border-left: 1px solid white;
    border-left-style: dashed;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    bordxer-radius: .75em;
    bordxer-top-left-radius: 0;
    bordxer-bottom-left-radius: 0;
}

.tiebreaker-option-buttons div {
    /* max-width: min-content; */
    /* max-height: min-content; */
}

.tiebreaker-option-buttons svg {
    widxth: 0;
    heixght: 0;
    overxflow: visible !important;
}

.tiebreaker-option-buttons > .up {
    flex: 1 1;
    border-bottom: 1px solid white;
    border-bottom-style: dashed;
    border-top-right-radius: inherit;
}

.tiebreaker-option-buttons > .down {
    flex: 1 1;
    border-bottom-right-radius: inherit;
}

.tiebreaker-option-buttons div.disabled svg > path {
    backgroxund-color: rgba(0, 0, 0, 0.15);
    stroke: darkgray;
}

.tiebreaker-option-buttons div:hover:not(.disabled)  {
    background-color: rgba(255, 255, 255, 0.5);
}

.tiebreaker-option-buttons div:not(.disabled)  {
    cursor: pointer;
}

.search-result-team {
    position: relative;
    height: 3em;
    width: fit-content;
    min-width: 10em;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: solid 1px white;
    border-radius: 5px;
    cursor: pointer;
}

.search-result-team:hover {
    background-color: var(--bs-gray-700);
}

.bracket-generate-button {
    position: absolute;
    bottom: var(--form-section-padding);
    right: var(--form-section-padding);
}
