
.team-management-page {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 1.5em;
}

.team-info-dropdown {
    border: white 1px solid;
    border-top: 0px;
    cursor: pointer;
    background-color: var(--background-color-light);
}

.team-info-dropdown:first-child {
    border-top: white 1px solid;
}

.team-info-dropdown-entry {
    border: white 1px solid;
    border-top: 0px;
    background-color: var(--background-color-light);
    padding-left: 10%;
}

.icon-button {
    border: white 1px solid;
    margin: 4px;
    padding: 2px;
    cursor: pointer;
}

.icon-button:hover {
    backdrop-filter: brightness(150%);
}