
:root {
    --form-section-padding: 2em;
    --form-section-padding-top: 1.5em;
}

.form-section {
    backdrop-filter: brightness(140%);
    border-radius: 2em;
    padding: var(--form-section-padding);
    padding-top: var(--form-section-padding-top);
    margin-bottom: 1em;
}

.form-section h2 {
    margin: 0;
}

.form-section > .section-title {
    margin-top: 1em;
}

.form-subsection {
    border: 1px solid white;
    border-radius: 1em;
    padding: 1em;
    padding-top: .75em;
    position: relative;
}

.form-subsection > .section-title {
    margin-top: .5em;
}

.warning {
    color:rgb(256, 64, 64);
}
