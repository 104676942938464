
#login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 1em;
    min-height: calc(100vh - var(--header-size) - var(--footer-size));
    padding-bottom: 2em;
}

#login-form-container > h1 {
    margin-top: 1em !important;
}

/* #login-form-container > p {
    margin: 0;
} */
