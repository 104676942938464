
:root {
    --bracket-line-width: 5em;
    --bracket-match-width: 10em;
    --score-input-width: 2em;
    --score-input-left-margin: 2px;
    --results-table-width: 11em;
    --results-table-logo-width: 2em;
}

.bracket-page {
    margin-top: 1.5em;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 1.5em;
}

.bracket-page > h1 {
    text-align: center;
    margin-top: 0em !important;
}

.tournament {
    display: flex;
    flex-direction: column;
}

.groups-section {
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
}

.tournament-section {
    max-width: 100%;
}

.bracket-container {
    overflow-x: auto;
    max-width: 100%;
    overflow: visible;
}

.bracket {
    overflow-x: scroll;
    width: max-content;
    /* margin-bottom: 2em; */
    margin-bottom: 1em;
    border-spacing: 0;
    overflow: visible;
}

.bracket-match {
    border: 1px solid white;
    display: inline-block;
    width: var(--bracket-match-width);
    min-width: 9em;
    border-radius: 5px;
    margin-top: .5em;
    margin-bottom: .5em;
}

.bracket-match-filler {
    margin-top: 1em;
    margin-bottom: 1em;
}

.bracket-match-team {
    width: 100%;
    padding: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    position: relative;
    border-radius: 0 0 4px 4px;
}

.bracket-match-team.top {
    border-bottom: 1px solid white;
    border-radius: 4px 4px 0 0;
}

.bracket-match-team-logo {
    height: 2em;
    width: 2em;
    min-width: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.bracket-match-team-name-container {
    position: relative;
    flex: 1 1;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    overflow-wrap: unset;
    max-width: calc(var(--bracket-match-width) - var(--score-input-left-margin) - var(--score-input-width) - .5em);
}

.bracket-match-team-name-container:hover {
    overflow: visible;
    min-width: calc(var(--bracket-match-width) - var(--score-input-left-margin) - var(--score-input-width) - 0.5em);
    max-width: fit-content;
    padding-right: .5em;
    border-radius: 3px;
    background-color: var(--body-background-color);
    z-index: 2;
}

.bracket-match-team-name {
    /* margin-right: .5em; */
    margin-left: .5em;
    overflow: hidden;
    overflow-wrap: unset;
    text-overflow: ellipsis;
}

.bracket-score-input-container {
    margin-left: -200em;
}

.bracket-score-input {
    margin-left: var(--score-input-left-margin);
    border-radius: 3px;
    width: var(--score-input-width);
    text-align: center;
}

.bracket-score-input:enabled::-webkit-inner-spin-button, 
.bracket-score-input:enabled::-webkit-outer-spin-button {
    opacity: 1;
}

.bracket-match-score-container {
    margin-left: -200em;
    width: var(--score-input-width);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-left: 1px solid white;
    border-radius: 0;
    border-bottom-right-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bracket-match-score-container.enabled {
    background: rgba(164, 172, 178, 0.5);
    cursor: pointer;
}

.bracket-match-team.top .bracket-match-score-container {
    border-bottom-right-radius: 0;
    border-top-right-radius: 4px;
}

.bracket-match-score-container.disabled {
    background: var(--input-background-color);
    filter: brightness(70%);
    cursor: inherit;
}

.bracket-match-score {
    text-align: center;
}

.bracket-connector {
    display: flex;
    align-items: center;
    width:fit-content;
}

.bracket-feeder-matches {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.bracket-match-with-line {
    display: flex;
}

.bracket-line {
    width: var(--bracket-line-width);
}

.bracket-wide-gap {
    height: 100%;
    width: calc(var(--bracket-line-width) + var(--bracket-match-width));
}

.results-and-stats {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
    margin-bottom: 1em;
}

.results-table {
    border: 1px solid white;
    border-radius: 1em;
    padding: 1em;
    padding-top: .75em;
    position: relative;
    width: var(--results-table-width);
    max-widxth: 11em;
}

.results-table tr {
    border-bottom: 1px solid white;
    border-style: dotted;
    border-radius: 1em;
    padding: 1em;
    padding-top: .75em;
    position: relative;
    background-color: var(--body-background-color);
}

.results-table tr.gold {
    background-color: rgb(191, 172, 115);
}

.results-table tr.silver {
    background-color: rgb(179, 179, 179);
}

.results-table tr.bronze {
    background-color: rgb(187, 152, 117);
}

.results-table tr.gold, .results-table tr.silver, .results-table tr.bronze {
    border-style: solid;
}

.results-table tr.gold > td, .results-table tr.silver > td, .results-table tr.bronze > td {
    border-style: solid;
}

.results-table td {
    padding: .3em;
    position: relative;
    max-width: calc(var(--results-table-width) - var(--results-table-logo-width));
    background-color: inherit;
    height: 2em;
}

.results-table td:first-child {
    border-right: 1px solid white;
    border-style:dotted;
    maxx-width: min-content;
    widxth:1px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    width: 1%
}

.results-table-team-name-container {
    position: relative;
    flex: 1 1;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    overflow-wrap: unset;
    max-width: calc(var(--results-table-width) - var(--results-table-logo-width));
}

.results-table-team-name-container:hover {
    overflow: visible;
    max-width: none;
    width: max-content;
    border-radius: 3px;
    background-color: inherit;
}

.results-table-team-logo {
    height: var(--results-table-logo-width);
    width: var(--results-table-logo-width);
    min-width: var(--results-table-logo-width);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

tr.results-table-button {
    cursor: pointer;
    border-top: solid 1px;
}

.results-table-button > td {
    padding: 0;
}

.results-table-button:hover {
    filter: brightness(140%);
}

.match-score-modal .modal-content {
    padding: .5em;
}

.match-score-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.match-score-modal-teams {
    display: grid;
    grid-template-columns: 8fr 1fr 8fr;
    column-gap: 1em;
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1em; */
}

.match-score-modal-scores {
    display: grid;
    grid-template-columns: 8fr 8fr;
    column-gap: 1em;
}

.match-score-modal-teams h5 {
    margin: 0;
    
    overflow: hidden;
    overflow-wrap: unset;
    text-overflow: ellipsis;
}

.match-score-modal-teams > .left {
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .5em;
    white-space: nowrap;
    overflow: hidden;
    overflow-wrap: unset;
}

.match-score-modal-scores > .left {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.match-score-modal-teams > .vs {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.match-score-modal-teams > .right {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .5em;
    white-space: nowrap;
    overflow: hidden;
    overflow-wrap: unset;
}

.match-score-modal-scores > .right {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.match-score-modal-score {
    width: fit-content;
    background-color: var(--input-background-color);
    border-radius: .5em;
    margin-top: .5em;
    padding: .5em;
    border: 1px solid white;
    cursor: pointer;
}

table.points-selection-table > tbody tr > td {
    text-align: center;
    vertical-align: middle;
    padding: 5px;
}

.tournament-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1em;
    flex-wrap: wrap;
}

.tournament-button-wrapper {
    width: fit-content;
}
