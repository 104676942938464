@import
url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');

html {
    font-family: Comfortaa, Arial, Helvetica, sans-serif;
}

:root {
    --footer-size: 64px;
    --header-size: 86px;
    --number-input-height: 3em;
    --number-input-input-width: 4.5em;
    --number-input-small-height: 2em;
    --number-input-small-input-width: 3em;
    --input-background-color: var(--bs-gray-600);
    --input-hover-background-color: var(--bs-gray-500);
    --body-background-color: var(--bs-gray-800);
    --background-color-light: var(--bs-gray-700);
}

body {
    position: relative;
    min-height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--body-background-color) !important;
    color: white !important;
}

#content-wrap {
    padding-bottom: var(--footer-size);
    flex: 1;
    display: flex;
    flex-direction: column;
}

.page-content {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 1em;
    margin-bottom: 1em;
}

/* So the dropdown nav looks good on narrow screens */
nav {
    text-align: right;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0;
}

.navbar {
    min-height: var(--header-size);
    flex: 0;
}

.navbar-nav {
    --bs-nav-link-font-size: 1.7rem;
}

.navbar-brand {
    --bs-navbar-brand-font-size: calc(2rem + .5vw);
}

.navbar-wrap {
    flex: 1;
}

#sign-out {
    cursor: pointer;
}

#new-bracket {
    cursor: pointer;
}

@media (min-width: 992px) {
    .navbar-wrap {
        display: flex;
        justify-content: end;
    }
}

footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    height: var(--footer-size);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
}

footer > hr {
    order: 2;
}

footer > div:nth-child(2) {
    order: 1;
    text-align: left;
}

footer > div:last-child {
    order: 3;
    text-align: right;
}

footer > p {
    margin-bottom: 0;
}

@media(max-height: 500px) {
    footer {
        bottom: calc(-1*var(--footer-size));
    }

    #content-wrap {
        padding: 0;
    }
}

::-webkit-scrollbar {
    display: none;
}

h1 {
    margin-top: 1.5em !important;
    text-align: center;
}

.form-control {
    background: var(--input-background-color) !important;
    color: white !important;
    margin-bottom: 1rem;
}

.form-control::placeholder {
    color:var(--bs-gray-400);
}

.form-check {
    margin-bottom: 1rem !important;
}

.form-check .form-check-input:not(:checked) {
    background-color: var(--input-background-color) !important;
    border-color: white !important;
}

.dropdown-bg {
    background-color: var(--bs-gray-700) !important;
}

.modal-min > .modal-dialog {
    max-width: fit-content;
}

/* Custom Input Types */

.double-label-switch {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: fit-content;
    max-width: 100%;
    justify-content: start;
    padding-left: 0 !important;
}

.double-label-switch.disabled {
    color: var(--bs-gray-400);
    font-style: italic;
}

.double-label-switch-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    max-width: 100%;
    justify-content: start;
    padding-left: 0 !important;
}

.double-label-switch-options > .label {
    text-align: center;
}

.double-label-switch-options > .form-check-input {
    margin-left: .3em !important;
    margin-right: .3em !important;
    margin-top: 0 !important;
}

.input-with-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    max-width: 100%;
    justify-content: start;
    padding-left: 0 !important;
    flex-wrap: wrap;
    margin-bottom: .5em;
}

.label.title {
    margin-right: .3em;
    font-weight: bold;
    width: max-content;
    white-space: nowrap;
}

.number-input {
    flex-direction: row;
    flex-wrap: nowrap !important;
    width: fit-content !important;
}

.number-input > input {
    width: var(--number-input-input-width) !important;
    margin: 0 !important;
    height: var(--number-input-height);
}

.number-input.small > input {
    width: var(--number-input-small-input-width) !important;
    height: var(--number-input-small-height);
}

.number-input-buttons {
    height: var(--number-input-height);
    display: flex;
    flex-direction: column;
}

.number-input.small .number-input-buttons {
    height: var(--number-input-small-height);
}

.number-input-buttons > button {
    height: calc(var(--number-input-height) / 2);
    width: calc(var(--number-input-height) / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: var(--input-background-color);
    border-color: white;
    color: white;
}

.number-input.small .number-input-buttons > button {
    height: calc(var(--number-input-small-height) / 2);
    width: calc(var(--number-input-small-height) / 2);
}

.number-input-buttons > button.up {
    border-bottom-right-radius: 0;
}

.number-input-buttons > button.down {
    border-top-right-radius: 0;
}

.number-input-buttons > button:hover {
    background-color: var(--bs-gray-700);
    border-color: white;
}

.modal {
    --bs-modal-bg: var(--bs-gray-900) !important;
    --bs-modal-border-color: white !important;
    --bs-modal-footer-border-width: 0 !important;
}

.with-tooltip {
    position: relative;
    display: inline-block;
}

.with-tooltip:hover::before {
    content: attr(tooltip);
    position: absolute;
    top: 100%;
    right: 0;
    white-space: normal;
    background-color: var(--bs-gray-700);
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 100%;
    text-align: center;
    z-index: 1;
}

.page-container {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

.form-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 1em;
    min-height: calc(100vh - var(--header-size) - var(--footer-size));
    padding-bottom: 2em;
}

p {
    margin: 0;
}
