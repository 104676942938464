
.login-form {
    background-color: var(--bs-gray-700) !important;
    color: white !important;
    border-radius: 0.375rem !important;
    width: 360px;
}

@media(max-width: calc(360px / .9)) {
    .login-form {
        min-width: 0;
        width: 90% !important;
    }
}

.register-form {
    background-color: var(--bs-gray-700) !important;
    color: white !important;
    border-radius: 0.375rem !important;
    min-width: 400px;
    width: 50%;
}

@media(max-width: calc(400px / .9)) {
    .register-form {
        min-width: 0;
        width: 90% !important;
    }
}

.reset-password-form {
    background-color: var(--bs-gray-700) !important;
    color: white !important;
    border-radius: 0.375rem !important;
    width: 350px;
}

@media(max-width: calc(350px / .9)) {
    .reset-password-form {
        min-width: 0;
        width: 90% !important;
    }
}

.enter-code-form {
    background-color: var(--bs-gray-700) !important;
    color: white !important;
    border-radius: 0.375rem !important;
    width: 400px;
}

@media(max-width: calc(400px / .9)) {
    .enter-code-form {
        min-width: 0;
        width: 90% !important;
    }
}

.choose-username-form {
    background-color: var(--bs-gray-700) !important;
    color: white !important;
    border-radius: 0.375rem !important;
    width: 400px;
}

@media(max-width: calc(400px / .9)) {
    .choose-username-form {
        min-width: 0;
        width: 90% !important;
    }
}

.verify-email-form {
    background-color: var(--bs-gray-700) !important;
    color: white !important;
    border-radius: 0.375rem !important;
    width: 430px;
}

@media(max-width: calc(430px / .9)) {
    .verify-email-form {
        min-width: 0;
        width: 90% !important;
    }
}

/* .sign-in-dropdown {
    display: flex !important;
    flex-direction: column;
    align-items: end;
} */

.login-form p {
    text-align: end;
}

.login-or-register {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.login-or-register > div {
    flex: 1;
}
