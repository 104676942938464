
:root {
    --home-title-font-size: 15vmin
}

.home-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#home {
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    min-height: calc(100vh - var(--header-size) - var(--footer-size));
}

.big-space {
  flex: 3;
}

.small-space {
  flex: 1;
}

#home .h1-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
}

#home h1 {
    margin: 0 !important;
    font-size: var(--home-title-font-size) !important;
}

#home .p-container {
    flex: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#home p {
    font-size: 3vmin;
    text-align: center;
    margin: 0;
}

#home .button-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

#big-button {
    font-size: 4vmin;
    padding: 2vmin;
    width: max-content;
}

#background {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    border: none;
    pointer-events: none;
}
