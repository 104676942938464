
.dashboard-grid-container {
    margin-left: 3em;
    margin-right: 3em;
    margin-bottom: 2em;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 1.5em;
}

.dashboard-grid h3,
.dashboard-grid h5 {
    text-align: center;
}

.events-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1em;
    row-gap: 1em;
}

@media(max-width: 1200px) {
    .events-grid {
        grid-template-columns: 1fr 1fr;
    }

    .events-grid > :nth-child(1) {
        grid-column: span 2; /* Occupies two columns */
    }
}

@media(max-width: 900px) {
    .events-grid {
        grid-template-columns: 1fr;
    }

    .events-grid > :nth-child(1) {
        grid-column: span 1;
    }
}

@media(max-width: 800px) {
    .dashboard-grid {
        grid-template-columns: 1fr;
    }

    .events-grid {
        grid-template-columns: 1fr 1fr;
    }

    .events-grid > :nth-child(1) {
        grid-column: span 2; /* Occupies two columns */
    }
}

@media(max-width: 600px) {
    .events-grid {
        grid-template-columns: 1fr;
    }

    .events-grid > :nth-child(1) {
        grid-column: span 1;
    }
}

.tournament-list-item:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: 1px solid white;
}

.tournament-list-item:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.tournament-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid white;
    border-top: 0;
    border-radius: 0;
    height: 3.5em;
    /* max-height: 3.5em; */
    padding-left: .3em;
    padding-right: .3em;
    /* margin-bottom: .2em; */
    padding: .25em;
}

.tournament-list-item:hover {
    background-color: var(--input-background-color);
}

.tournament-list-name {
    margin-right: .15em;
    text-align: center;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    word-break: break-word;
}

.tournament-list-date {
    margin-left: .15em;
}

.user-stats-table {
    /* box-sizing: border-box; */
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; */
}

/* .user-stats-table > .row:nth-child(1) {
    border-top: 1px solid white;
} */

.user-stats-table > .row {
    outline: 1px solid white;
    /* box-sizing: border-box;
    margin-top: -1px;
    margin-left: -1px; */
    margin: 1px;
    /* border-top: 0; */
}

/* .user-stats-table > .row > .col:nth-child(1) {
    border-left: 0;
} */

.user-stats-table > .row > .col {
    /* border-left: 1px solid white; */
    outline: 1px solid white;
    /* box-sizing: border-box;
    margin-top: -1px;
    margin-left: -1px; */
    /* margin: 1px; */
    padding: 0;
}

.user-stats-content {
    padding: 4px;
    padding-top: 2px;
}

.user-stats-label {
    font-size: .75em;
}

.user-stats-label + * {
    /* margin-top: -8px; */
}

.user-stats-stat {
    margin-top: -8px;
    text-align: center;
    font-size: 2em;
}

.user-stats-results-list > .row {
    margin: 0;
    justify-content: space-between;
}

.user-stats-results-list > .row > * {
    max-width: max-content;
    font-size: .9em;
}
